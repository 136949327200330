import React from "react"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import SEO from "../../../../../components/seo"
import TermsMobile from "../../../../../components/body/pages/mobile/ng/legal/terms"

const TermsPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/terms/"}
      title="Terms & Conditions | Kuda | The Money App for Africans"
    />
    <TermsMobile />
  </NoheaderFooter>
)

export default TermsPage
